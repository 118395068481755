/*
 * @Author: 李明明
 * @Date: 2021-02-01 11:32:22
 * @LastEditTime: 2022-01-10 14:55:57
 * @LastEditors: 李明明
 * @Description: 封装axios
 * @FilePath: /zs-query/src/utils/request.ts
 * @代码版权方：'政府采购信息网'
 */
import { ElLoading, ElMessage } from "element-plus";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
const baseUrl = process.env.VUE_APP_BASE_URL;
class HttpRequest {
  [x: string]: any;
  baseURL: string;
  timeout: number;
  constructor() {
    // 这里可以增加实例属性 后台接口的路径 开发模式和生产模式不一样
    this.baseURL = baseUrl;
    this.timeout = 10000; // 30秒后请求超时
  }
  setInterceptors(instance: AxiosInstance) {
    const loadingInstance = ElLoading.service({
      text: "数据加载中",
      background: "rgba(0,0,0,.3)",
    });
    instance.interceptors.response.use(
      (response) => {
        setTimeout(() => {
          loadingInstance.close();
        }, 200);
        let status = 0;
        if (response.data?.status == undefined) {
          status = response.data?.resCode;
        } else {
          status = response.data?.status;
        }
        switch (status) {
          case 200:
            return Promise.resolve(response.data);
            break;
            break;
          case -145:
            return Promise.resolve(response.data);
            break;
          // 表单未开始
          case -146:
            return Promise.resolve(response.data);
            break;
          // 表单已结束
          case -147:
            return Promise.resolve(response.data);
            break;
          // 填写信息错误
          case -148:
            return Promise.resolve(response.data);
            break;
          // 提交次数已达上线
          case -149:
            return Promise.resolve(response.data);
            break;
          // 当前表单不允许修改
          case -150:
            return Promise.resolve(response.data);
            break;
          case -151:
            return Promise.resolve(response.data);
            break;
          case -152:
            return Promise.resolve(response.data);
            break;
          case -154:
            return Promise.resolve(response.data);
            break;
          default:
            ElMessage.error(response.data.resMessage);
            return Promise.reject(response.data);
            break;
        }
      },
      (error) => {
        setTimeout(() => {
          loadingInstance.close();
        }, 200);

        ElMessage.error(error);
        switch (status) {
          case "401":
            console.log(1);

            break;
          case "-115":
            console.log("文章失效");

            break;
          default:
            console.log(1);
            break;
        }
        return Promise.reject(error);
      }
    );
  }
  mergeOptions(options: any) {
    return { baseURL: this.baseURL, timeout: this.timeout, ...options };
  }
  request(options: any) {
    // 每个实例的拦截器和其他人无关，如果使用全局的实例，我想给每次请求单独配置拦截器就所不到
    const instance = axios.create(); // 创建axios实例
    this.setInterceptors(instance);
    const opts = this.mergeOptions(options);
    return instance(opts);
  }
  get(url: string, data) {
    return this.request({
      method: "get",
      url,
      params: data,
    });
  }
  post(url: string, data) {
    return this.request({
      method: "post",
      url,
      data: data,
    });
  }
}
export default new HttpRequest();
