/*
 * @Author: 李明明
 * @Date: 2021-02-18 10:11:07
 * @LastEditTime: 2023-09-01 14:22:36
 * @LastEditors: 李明明
 * @Description: 证书查询接口
 * @FilePath: /证书查询/zs-query/src/api/zs.js
 * @代码版权方：'政府采购信息网'
 */
import zsApi from "./config/zs.js";
import axios from "@/utils/request";

// 获取目录页数据
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getByIdent = (options) => axios.get(zsApi.getByIdent, options);

// 山西获取证书信息
export const listShanxiCertificate = (options) => axios.get(zsApi.listShanxiCertificate, options);
// 根据专家编码获取证书信息
export const queryByCode = (options) => axios.get(zsApi.queryByCode, options);



