/*
 * @Author: 李明明
 * @Date: 2021-02-18 10:06:02
 * @LastEditTime: 2023-09-01 14:54:31
 * @LastEditors: 李明明
 * @Description: 证书查询接口
 * @FilePath: /证书查询/zs-query/src/api/config/zs.js
 * @代码版权方：'政府采购信息网'
 */
// 栏目导航接口
export default {
  // 获取目录页数据
  getByIdent: "/ykt_java/certificate/getByIdent",
  // 山西获取证书信息
  listShanxiCertificate: "/ykt_java/certificate/listShanxiCertificate",
  // 根据专家编码获取证书信息
  queryByCode: "/ykt_java/certificate/queryByCode",
};
